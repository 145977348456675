import React from 'react';
import '../Assets/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='navbar'>
       <div className='navbar-left'>
            <Link to={"/contact"}>CONTACT</Link>
            <Link to={"/"}>PORTFOLIO</Link>
        </div>
        <div className='navbar-center'>
            <Link to={"/"}>ŞEVVAL YİTMEN</Link>
        </div>
    </nav>
  );
}

export default Navbar;
