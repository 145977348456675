import React, { useState } from "react";
import '../Assets/Home.css';

function Home() {
   // Tasarımları filtrelemek için bir state kullanıyoruz.
   const [activeFilter, setActiveFilter] = useState(0);

   // Tüm tasarımları burada listeleyebiliriz.
   const categories = [
     { id: 0, category: "All"},
     { id: 1, category: "Social Media"},
     { id: 2, category: "UX&UI Design" },
     { id: 3, category: "Posters" },
     { id: 4, category: "3D Modelling"},
     { id: 5, category: "Typography Design"},
     { id: 6, category: "Illustrations"},
     { id: 7, category: "Spatial Interaction Design"},
     { id: 8, category: "Sketches"},
   ];

   const items = [
    { id: 1, categoryId: 1, imgSrc: "1_social_media/1.jpg", desc:"social media design work"},
    { id: 2, categoryId: 1, imgSrc: "1_social_media/2.jpg", desc:"social media design work"},
    { id: 3, categoryId: 1, imgSrc: "1_social_media/3.jpg", desc:"social media design work"},
    { id: 4, categoryId: 2, imgSrc: "2_ux&ui_design/1_kapak.jpg", desc:"a messaging application design for communication design students"},
    { id: 5, categoryId: 2, imgSrc: "2_ux&ui_design/2.png", desc:"3d english dictionary app design for children with dyslexia"},
    { id: 6, categoryId: 3, imgSrc: "3_posters/1.png", desc:"this poster shows the average number of minutes a person burns candles per day in a week"},
    { id: 7, categoryId: 3, imgSrc: "3_posters/2.png", desc:"this poster shows the principles of good design in an abstract way"},
    { id: 8, categoryId: 3, imgSrc: "3_posters/4.png", desc:"poster for a spatial interaction design"},
    { id: 9, categoryId: 3, imgSrc: "3_posters/5.png", desc:"poster designed for a Project"},
    { id: 10, categoryId: 3, imgSrc: "3_posters/6.png", desc:"poster of a ui/ux design"},
    { id: 11, categoryId: 3, imgSrc: "3_posters/7.png", desc:"poster for information design"},
    { id: 12, categoryId: 3, imgSrc: "3_posters/8.png", desc:"poster of an app design"},
    { id: 13, categoryId: 3, imgSrc: "3_posters/9.png", desc:"poster for a spatial interaction design"},
    { id: 14, categoryId: 4, imgSrc: "4_3d_modeling/1_kapak.png", desc:"isometric kitchen design"},
    { id: 15, categoryId: 4, imgSrc: "4_3d_modeling/2.png", desc:"isometric classroom design"},
    { id: 16, categoryId: 4, imgSrc: "4_3d_modeling/3.png", desc:"isometric farm design"},
    { id: 17, categoryId: 4, imgSrc: "4_3d_modeling/4.png", desc:"isometric dining hall design"},
    { id: 18, categoryId: 5, imgSrc: "5_typography_design/1.jpg", desc:"typography design work"},
    { id: 19, categoryId: 5, imgSrc: "5_typography_design/2.jpg", desc:"typography design work"},
    { id: 20, categoryId: 5, imgSrc: "5_typography_design/3_kapak.jpg", desc:"typography design work"},
    { id: 21, categoryId: 6, imgSrc: "6_illustrations/1_kapak.jpg", desc:"an illustration inspired by the series 'lovestruck in the city'"},
    { id: 22, categoryId: 6, imgSrc: "6_illustrations/2.png", desc:"fictional game character illustration"},
    { id: 23, categoryId: 6, imgSrc: "6_illustrations/3.png", desc:"chibie version"},
    { id: 24, categoryId: 6, imgSrc: "6_illustrations/4.jpg", desc:"game scene"},
    { id: 25, categoryId: 6, imgSrc: "6_illustrations/5.jpg", desc:"sketch of the character"},
    { id: 26, categoryId: 6, imgSrc: "6_illustrations/6.png", desc:"character sketches"},
    { id: 27, categoryId: 6, imgSrc: "6_illustrations/7.jpg", desc:"character sketches"},
    { id: 28, categoryId: 6, imgSrc: "6_illustrations/8.png", desc:"user manual for a computer fan"},
    { id: 29, categoryId: 6, imgSrc: "6_illustrations/9.png", desc:"pixel ship design for a game"},
    { id: 30, categoryId: 6, imgSrc: "6_illustrations/10.png", desc:"pixel space designs for a game"},
    { id: 31, categoryId: 6, imgSrc: "6_illustrations/11.png", desc:"pixel space designs for a game"},
    { id: 32, categoryId: 7, imgSrc: "7_spatial_interaction_design/1.png", desc:"Focusing on feelings such as humiliation, shame, and insult, the aim is to give users a gamified dining hall experience where they feel bad and uncomfortable. The word “bully” was used to characterize the space. The space was characterized with the questions “What would a bully do if he was in a dining hall?” and “How did the bully treat the dining hall users?”"},
    { id: 33, categoryId: 7, imgSrc: "7_spatial_interaction_design/2.png", desc:"The work titled Concertino for the Sea in Cinili Bath, which is included in the 17th Istanbul Biennial, draws attention to the grave consequences of environmental pollution. Human-space interaction was added to this work with the project mapping system. This interaction reveals how people pollute nature."},
    { id: 34, categoryId: 8, imgSrc: "8_sketches/eskizler_kapak.png", desc:"sketch works"},
   ];
 
   const covers = [
    { id: 1, categoryId: 1, categoryName: "Social Media", imgSrc: "1_social_media/kapak.jpg"},
    { id: 2, categoryId: 2, categoryName: "UX&UI Design",imgSrc: "2_ux&ui_design/1_kapak.jpg"},
    { id: 3, categoryId: 3, categoryName: "Posters",imgSrc: "3_posters/1_kapak.png"},
    { id: 4, categoryId: 4, categoryName: "3D Modelling",imgSrc: "4_3d_modeling/1_kapak.png"},
    { id: 5, categoryId: 5, categoryName: "Typography Design",imgSrc: "5_typography_design/3_kapak.jpg"},
    { id: 6, categoryId: 6, categoryName: "Illustrations",imgSrc: "6_illustrations/1_kapak.jpg"},
    { id: 7, categoryId: 7, categoryName: "Spatial Interaction Design",imgSrc: "7_spatial_interaction_design/1_kapak.png"},
    { id: 8, categoryId: 8, categoryName: "Sketches",imgSrc: "8_sketches/eskizler_kapak.png"}
   ];

   // Filtreleme butonlarına tıklayınca çalışacak fonksiyon.
   const handleFilterClick = (filter) => {
     setActiveFilter(filter);
   };
 
   // Aktif filtreye göre tasarımları filtreliyoruz.
   const filteredItems = activeFilter === 0 ? covers : items.filter((item) => item.categoryId === activeFilter);
 
   return (
     <div className="main">
        <div className="filter-buttons">
         {categories.map((category) => (
           <button
             key={category.id}
             className={activeFilter === category.id ? "active" : ""}
             onClick={() => handleFilterClick(category.id)}
           >
             {category.category}
           </button>
         ))}
       </div>

       <div className={activeFilter == 0 ? "design-cover-grid" : "design-grid"}>
        {filteredItems.map((item) => (
          <div
            key={item.id}
            className={activeFilter == 0 ? "design-cover-item" : "design-item"}
            onClick={() => handleFilterClick(item.categoryId)}  
          >
            <div className="item-hover">
              {activeFilter == 0 ? item.categoryName : item.desc}
            </div>
            <img src={require('../Assets/images/' + item.imgSrc)} alt="Design" />
          </div>
        ))}
      </div>
     </div>
   );
}

export default Home;
