import React from 'react';
import '../Assets/Contact.css';

const Contact = () => {
  return (
    <div className='contact'>
        <div className='top'>
            <h1>Contact Me</h1>
            <p>Empower your projects by reaching out to me for any need. Whether you're facing challenges or seeking insights, don't hesitate to drop me a line. Let's connect and explore possibilities together.</p>
        </div>
        <div className='bottom'>
            {/* <div className='phone'>
                <i className="fa-solid fa-phone"></i>
                <h3>Phone</h3>
                <p>For effective communication, you can leave a message via WhatsApp. We can arrange a meeting and talk.</p>
                <a href='tel:534313123'></a>
            </div> */}
            <div className='email'>
                <i className="fa-solid fa-envelope"></i>
                <h3>Email</h3>
                <p>You can directly ask me questions and make requests related to your needs via email. Feel free to talk!</p>
                <a href='mailto:sevvalyitmen@gmail.com'>sevvalyitmen@gmail.com</a>
            </div>
            <div className='linkedin'>
                <i className="fa-brands fa-linkedin"></i>
                <h3>Linkedin</h3>
                <p>You can view my Linkedin profile and contact me from here.</p>
                <a href='https://www.linkedin.com/in/şevval-yitmen-8a73b588'>Şevval Yitmen</a>
            </div>
        </div>
    </div>
  );
}

export default Contact;
